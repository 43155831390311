import Vue from 'vue'
import Vuex from 'vuex';


Vue.use(Vuex);



export default new Vuex.Store({
    state: {
        gebot: {
            new: 100,
            act: 100,
            anfrage: 0,
            saleuser: {},
            schatz: 0,
            start: 0
        },
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
          },
        onlineuser: 0
    },
    actions: {
    },
    mutations: {
        SET_GEBOT(state, data){
            if (data.anfrage>0){
                state.gebot.anfrage = data.anfrage;
                state.gebot.onlineuser = data.gebotuser;
                state.gebot.onlinegebot = data.anfrage;
            } else {
                state.gebot = data;
            }
        },
        SET_NEWSTART(state, data){
            state.gebot.start = state.gebot.start + data;
        },
        SET_ONLINEUSER(state, data){
            state.onlineuser = data;
        },
        SET_NEWSCHATZ(state, data){
            state.gebot.schatz = state.gebot.schatz + data;
        },

        SOCKET_ONOPEN (state, event)  {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE (state, event)  {
            console.log(event)
            state.socket.isConnected = false
        },
        SOCKET_ONERROR (state, event)  {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE (state, message)  {
            state.socket.message = message
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
    },
    getters: {

    }
})