import Vue from 'vue'
import App from './App.vue'

import store from './states/store.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://viancobackend.swissliveauction.ch/ws', {
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  store: store, format: 'json'
})
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// const connection = io.connect('https://viancobackend.swissliveauction.ch/', { transports: ['websocket'], upgrade: false});


new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
