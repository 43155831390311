<template>
  <div class="cowinfo">
    <h1>{{ cowname }}</h1><br>
    Katalognr.: {{ katnr }}<br>
    
  </div>
</template>

<script>

export default {
  name: 'cowinfo',
  data() {
    return {
    };
  },
  props: {
  },
  components: {},
  computed: {
    cowname(){
        return this.$store.state.gebot.tiername
    },
    auktnr(){
        return this.$store.state.gebot.auktnr
    },
    katnr(){
        return this.$store.state.gebot.katnr 
    },
  },
  
}
</script>

<style scoped>
@import '../../scss/classes.scss';

.cowinfo{
  margin: 50px;
  font-size: 50px;
}

h1{
    font-size: 60px;
  }


</style>
